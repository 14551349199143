import React, { useState, useEffect } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

import logo1 from "../assets/software1.jpeg";

function Inicio() {
  const [formData, setFormData] = useState({
    idea: "",
    name: "",
    surname: "",
    email: "",
    company: "",
    companytype: "",
    country: "",
  });

  const [recaptchaToken, setRecaptchaToken] = useState(""); // Guardamos el token de reCAPTCHA
  const [imageLoaded, setImageLoaded] = useState(false); // Para manejar el estado de la imagen cargada


  // Cargamos la imagen de fondo
  useEffect(() => {
    const img = new Image();
    img.src = logo1;
    img.onload = () => setImageLoaded(true);
  }, []);

  // Cambiar los datos del formulario
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Gestionar la validación del reCAPTCHA
  const handleRecaptcha = (token) => {
    setRecaptchaToken(token);
  };

  // Enviar formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert("Por favor, completa el reCAPTCHA.");
      return;
    }

    try {
      const response = await axios.post("https://beeneu.dev/send-email", {
        ...formData,
        recaptchaToken,
      });
      console.log("Respuesta del servidor:", response.data);
    } catch (error) {
      console.error("Error al enviar los datos:", error);
    }
  };



  return (
    <div className="bg-slate-700 min-h-screen">
      {!imageLoaded && (
        <div className="text-white text-center">Cargando imagen...</div>
      )}
      {imageLoaded && (
        <>
          <div id="carousel" className="carousel-container">
            <div
              className="carousel-inner relative"
              style={{
                backgroundImage: `url(${logo1})`,
              }}
            >
              <div className="absolute inset-0 flex items-center justify-start text-left">
                <div className="text-container">
                  <div className="text-md-large d-none d-lg-none align-text">
                    <span className="text-white move-left">
                      Sé todo tu potencial
                    </span>
                  </div>
                  <div className="text-md-medium1 d-none d-lg-none align-text">
                    <span className="text-white">
                      Desarrollos de software, integrados y adaptados a tus
                      necesidades, combinando tecnología e innovación con un
                      enfoque personalizado para ayudarte a alcanzar tus
                      objetivos.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative flex flex-col items-start justify-center  text-slate-700 py-12 ml-8 mt-24 space-y-6">
            <h2 className="text-md-large1 ml-8 my-8 mx-8 move-up move-down move-left move-right small-heading">
              Contanos cómo {""}
              <span className="underline-custom">podemos ofrecerte</span> apoyo.
            </h2>

            <div className="absolute top-10 left-10 p-6 rounded-lg shadow-lg w-96 max-w-lg ml-auto modal-medio">
              <form className="space-y-8" onSubmit={handleSubmit}>
                <div>
                  <label className="text-md-medium  mb-2">
                    Tu idea/objetivo
                  </label>
                  <textarea
                    className="custom-input w-full px-2 py-2 border rounded resize-none"
                    maxLength="300"
                    rows="4"
                    name="idea"
                    placeholder="Describe tu objetivo (0 a 300 caracteres)"
                    value={formData.idea}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="w-full mb-6">
                  <label className="block text-md-small mb-2">Nombre</label>
                  <input
                    type="text"
                    className="custom-input w-full px-4 py-2"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>

                <div className="w-full mb-6">
                  <label className="block text-md-small mb-2">Apellido</label>
                  <input
                    type="text"
                    className="custom-input w-full px-4 py-2"
                    name="surname"
                    value={formData.surname}
                    onChange={handleChange}
                  />
                </div>

                <div className="w-full mb-6">
                  <label className="block text-md-small mb-2">Email</label>
                  <input
                    type="email"
                    className="custom-input w-full px-4 py-2"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>

                <div className="w-full mb-6">
                  <label className="block text-md-small mb-2">Empresa</label>
                  <input
                    type="text"
                    className="custom-input w-full px-4 py-2"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full mb-6">
                  <label className="block text-md-small mb-2">
                    Tipo de empresa
                  </label>
                  <input
                    type="text"
                    className="custom-input w-full px-4 py-2"
                    name="companytype"
                    value={formData.companytype}
                    onChange={handleChange}
                  />
                </div>

                <div className="w-full mb-6">
                  <label className="block text-md-small mb-2">País</label>
                  <select
                    className="custom-input w-full px-4 py-2"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                  >
                    <option value="">Selecciona un país</option>
                    <option>Argentina</option>
                    <option>Brasil</option>
                    <option>Chile</option>
                    <option>México</option>
                    <option>Estados Unidos</option>
                    <option>Canadá</option>
                    <option>España</option>
                    <option>Francia</option>
                    <option>Reino Unido</option>
                    <option>Alemania</option>
                    <option>Italia</option>
                    <option>Australia</option>
                    <option>Japón</option>
                    <option>China</option>
                    <option>India</option>
                    <option>Sudáfrica</option>
                    <option>Rusia</option>
                    <option>Colombia</option>
                    <option>Perú</option>
                    <option>Venezuela</option>
                    <option>Uruguay</option>
                    <option>Paraguay</option>
                    <option>Bolivia</option>
                    <option>Ecuador</option>
                    <option>Guatemala</option>
                    <option>Honduras</option>
                    <option>Costa Rica</option>
                    <option>Panamá</option>
                    <option>Nicaragua</option>
                    <option>El Salvador</option>
                    <option>Cuba</option>
                    <option>Puerto Rico</option>
                    <option>República Dominicana</option>
                    <option>Portugal</option>
                    <option>Suiza</option>
                    <option>Países Bajos</option>
                    <option>Dinamarca</option>
                    <option>Suecia</option>
                    <option>Noruega</option>
                    <option>Finlandia</option>
                    <option>Bélgica</option>
                    <option>Polonia</option>
                    <option>Grecia</option>
                    <option>Hungría</option>
                    <option>Croacia</option>
                    <option>Serbia</option>
                    <option>Eslovenia</option>
                    <option>Rumania</option>
                    <option>Ucrania</option>
                    <option>Turquía</option>
                    <option>Israel</option>
                    <option>Egipto</option>
                    <option>Marruecos</option>
                    <option>Túnez</option>
                    <option>Argelia</option>
                    <option>Arabia Saudita</option>
                    <option>Emiratos Árabes Unidos</option>
                    <option>Irán</option>
                    <option>Pakistán</option>
                    <option>Bangladés</option>
                    <option>Filipinas</option>
                    <option>Corea del Sur</option>
                    <option>Vietnam</option>
                    <option>Tailandia</option>
                    <option>Malasia</option>
                    <option>Indonesia</option>
                    <option>Singapur</option>
                    <option>Hong Kong</option>
                    <option>Nueva Zelanda</option>
                    <option>Kenia</option>
                    <option>Nigeria</option>
                    <option>Ghana</option>
                    <option>Etiopía</option>
                    <option>Senegal</option>
                    <option>Camerún</option>
                    <option>Zambia</option>
                    <option>Tanzania</option>
                  </select>
                </div>

                {/* reCAPTCHA se muestra aquí */}
                <ReCAPTCHA
                  sitekey="6Lf9904qAAAAAC72TVqojv2ALGSNvSnvS0-3L1MM" // Reemplaza con tu clave de reCAPTCHA
                  onChange={handleRecaptcha} // Función para manejar el token
                />

                <div className="captcha__description1 description mt-2 text-gray-500 text-sm">
                  This question is for testing whether or not you are a human
                  visitor and to prevent automated spam submissions.
                </div>

                <button
                  type="submit"
                  className="w-full1 bg-red-600 text-black py-2 rounded hover:bg-red-600 transition"
                >
                  Empezar con mi desarrollo
                </button>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Inicio;
