// Loading.js
import React from 'react';
import lampLogo from '../assets/lampara.amarilla-removebg-preview.png'; // Asegúrate de que sea un .png con fondo transparente

const Loading = () => {
  return (
    <div className="loading-container flex justify-center items-center min-h-screen bg-gray-100">
      <div className="loading-wrapper">
        <img
          src={lampLogo}
          alt="Lámpara cargando"
          className="lamp-image"  // Sin la clase de rotación
        />
        <div className="light-effect"></div> {/* Efecto de la luz que pasa */}
      </div>
    </div>
  );
};

export default Loading;
