import { Route, Routes, Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Inicio from './componentes/inicio';
import About from './componentes/about';
import Contact from './componentes/contact';
import logo from "./assets/Beeneuv3.png";
import './index.css';
import Loading from './componentes/loading';

function App() {
  const [loading, setLoading] = useState(false); // Estado de carga
  const location = useLocation(); // Hook para obtener la ruta actual
  const [isDarkTheme, setIsDarkTheme] = useState(false);



  // Mostrar el logo de carga cuando cambia la ruta
  useEffect(() => {
    setLoading(true); // Inicia la carga al cambiar de ruta
    const timeout = setTimeout(() => {
      setLoading(false); // Desactivar loading después de 1 segundo
    }, 1000);

    return () => clearTimeout(timeout); // Limpia el timeout al desmontar
  }, [location]); // Ejecutar efecto cuando cambia la ubicación (ruta)

  // Funciones para el cambio de tema
  const setTheme = (theme) => {
    document.documentElement.classList.remove('dark', 'light');
    document.documentElement.classList.add(theme);
    localStorage.setItem('theme', theme);
    setIsDarkTheme(theme === 'dark');
  };

  // Cargar el tema guardado al inicio
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    } else {
      // Usar el tema del sistema por defecto
      const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setTheme(isDarkMode ? 'dark' : 'light');
    }
  }, []);


  return (
    <div className="App">
      {loading && <Loading />} {/* Muestra el componente Loading si está activo */}
      {!loading && (
        <>
          {/* Header fijo */}
          <header className="bg-gray-600 shadow-lg relative z-20 custom-class">
          <nav className="navbar bg-slate-700 py-4">
             
              <Link to="/" className='navbar-logo-link'>
                <img src={logo} alt="Beeneu" className="navbar-logo" />
              </Link>

              <div className="nav-links-container space-x-4">
                <Link to="/" className="text-gray-800 hover:text-gray-600">Home</Link>
                <Link to="/about" className="text-gray-800 hover:text-gray-600">About</Link>
                <Link to="/contact" className="text-gray-800 hover:text-gray-600 cta-contactus-menu">Contact</Link>
              </div>

              <div className="flex space-x-4">
                <button
                  id="theme-toggle-btn"
                  onClick={() => setTheme(isDarkTheme ? 'light' : 'dark')}
                  className="emoji-button text-gray-800 hover:text-gray-600"
                >
                  {isDarkTheme ? '🌞' : '🌙'}
                </button>
              </div>
            </nav>

          </header>

          {/* Definimos las rutas */}
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>

          {/* Footer */}
          <footer className="custom-footer">
            <div className="footer-top">
              <div className="footer-logo">
                <Link to="/"></Link>
                <p>Powered by Beeneu, todos los derechos reservados.</p>
              </div>
              <div className="footer-contact">
                <h3>Contactanos</h3>
                <p>Escribinos</p>
                <a href="mailto:contacto@beeneu.dev">contacto@beeneu.dev</a>
              </div>
            </div>

            <div className="footer-bottom">
              <div className="footer-links">
                <Link to="/politica-de-confidencialidad">Política de Privacidad</Link>
                <Link to="/terminos-del-servicio">Términos del servicio</Link>
                <Link to="/sitemap">Site Map</Link>
                <Link to="/cookie-policy">Política de Cookies</Link>
                <Link to="/vulnerability-disclosure-policy">Política de Vulnerabilidad</Link>
              </div>
            </div>
          </footer>
        </>
      )}
    </div>
  );
}

export default App;
