import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Importar estilos base
import { Autoplay } from "swiper/modules";
import "swiper/css/autoplay";

import headerImage from "../assets/celesteimg.jpeg";

function About() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const frases = [
    "'Los datos son el nuevo petróleo' Clive Humby, pionero en ciencia de datos, 2006.",
    "'La creatividad es la inteligencia divirtiéndose.' Albert Einstein",
    "'La información es poder.' Francis Bacon, 1957.",
    "'En un mundo lleno de datos, encontrar el significado es el verdadero desafío.' John Tukey, 1977.",
    "'El objetivo es convertir los datos en información, y la información en conocimiento.' Carly Fiorina, 2004.",
  ];

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="about-container">
      <header
        className="about-header"
        style={{
          backgroundImage: `url(${headerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50% 65%",
          height: "600px",
          position: "relative",
          color: "#fff",
          display: imageLoaded ? "block" : "none",
        }}
      >
        <img
          src={headerImage}
          alt="background"
          onLoad={handleImageLoad}
          style={{ display: "none" }}
        />
        <div
          className="header-overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 className="header-title" style={{ fontSize: "150px" }}>
            nosotros
          </h1>
        </div>
      </header>

      <section className="about-content">
        <div className="about-row">
          <div className="about-column">
            <h2>.01 Somos</h2>
            <p>
              Una empresa independiente nacida en 2024 con un objetivo claro:
              "Conectar con empresas, analizar juntos los problemas y crear
              soluciones, obteniendo resultados que aumentaran la eficacia de
              nuestros clientes".
            </p>
          </div>
          <div className="about-column">
            <h2>.02 Disfrutamos</h2>
            <p>
              Creemos que la colaboración nos impulsa. Disfrutamos trabajar en
              equipo porque cada desafío se convierte en una oportunidad para
              crecer juntos, compartiendo ideas y creando soluciones que
              impactan.
            </p>
          </div>
        </div>
        <div className="about-row">
          <div className="about-column">
            <h2>.03 Como trabajamos</h2>
            <p>
              Aprovechamos al máximo la tecnología implementando plataformas de
              gestión de tickets, inteligencia artificial y metodologías
              estructuradas. Esto nos permite mantener procesos eficientes y
              garantizar un flujo de trabajo ágil y organizado.
            </p>
          </div>
          <div className="about-column">
            <h2>.04 Hacemos</h2>
            <p>
              Nos especializamos en el desarrollo de software personalizado,
              diseñando aplicaciones innovadoras que optimizan procesos y
              potencian el crecimiento de nuestros clientes. Desde la idea
              inicial hasta la implementación, creamos tecnología a medida para
              cada necesidad.
            </p>
          </div>
        </div>
      </section>

      <Swiper
        spaceBetween={30}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false, // Seguirá el autoplay después de interactuar
        }}
        pagination={{ clickable: true }} // Paginación activa
        loop={true} // Hacer que el carrusel sea infinito
        grabCursor={true} // Cursor tipo mano para arrastrar
        modules={[Autoplay]} // Usar solo los módulos necesarios
        className="mySwiper"
      >
        {frases.map((frase, index) => (
          <SwiperSlide key={index}>
            <div className="flex justify-center px-5 min-w-[calc(100%/5)] lg:min-w-[calc(100%/5)] text-center bg-white text-black rounded-2xl py-4 mx-4 shadow-lg">
              {frase}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <section
        className="call-to-action"
        style={{
          textAlign: "center",
          marginTop: "40px",
          display: "flex", // Aplicamos flexbox aquí también
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column", // Ajustamos el contenido verticalmente
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            fontSize: "24px",
            flexWrap: "wrap", // Para ajustar el contenido en pantallas pequeñas
          }}
        >
          <span className="header-title1" style={{ fontSize: "8vw" }}>
            {" "}
            {/* Adaptable en tamaño */}
            Work with us
          </span>
          <span style={{ fontSize: "30px" }}>➡️</span>
          <a
            href="contact"
            className="text-gray-800 hover:text-gray-600 cta-contactus-menu"
          >
            Contact
          </a>
        </div>
      </section>
    </div>
  );
}

export default About;
