import React, { useState, useEffect } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import logo2 from "../assets/notebookinformatica.jpeg";

function Contact() {
  const [formData, setFormData] = useState({
    idea: "",
    name: "",
    surname: "",
    email: "",
    company: "",
    companytype: "",
    country: "",
  });

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false); // Para manejar si la imagen está cargada
  const [loading, setLoading] = useState(true); // Para manejar el estado de carga del componente

  // Efecto para cargar la imagen de fondo y manejar el estado de carga general
  useEffect(() => {
    const img = new Image();
    img.src = logo2; // Carga la imagen de fondo
    img.onload = () => {
      setImageLoaded(true);
      setLoading(false); // La imagen está cargada, podemos dejar de mostrar el loader
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Manejar el token de reCAPTCHA
  const handleRecaptcha = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert("Por favor, completa el reCAPTCHA.");
      return;
    }

    try {
      const response = await axios.post("https://beeneu.dev/send-email", {
        ...formData,
        recaptchaToken,
      });
      console.log("Respuesta del servidor:", response.data);
    } catch (error) {
      console.error("Error al enviar los datos:", error);
    }
  };

  return (
    <div>
      {loading && ( // Mostrar mensaje de carga hasta que todo esté listo
        <div className="text-white text-center">Cargando...</div>
      )}

      {!loading && ( // Solo mostrar el contenido si todo está cargado
        <>
          <div>
            <div
              className="absolute w-full bg-cover bg-bottom border-b-4 border-[#f87c60] bg-no-repeat pt-10"
              style={{
                backgroundImage: `url(${logo2})`,
                height: "630px",
                backgroundSize: "19% auto",
                backgroundPosition: "center right 31%",
                clipPath: "inset(0 0 50px 0)",
              }}
            ></div>
            <h2 className="relative titulo-contact bottom-4 left-1/2 transform -translate-x-1/2 text-white text-2xl font-bold">
              Nuestro compromiso es ayudarte a potenciar tus
              <span className="text-ideas"> ideas</span>.
            </h2>
          </div>

          <div className="p-8 bg-white-background bg-gray-100 rounded-lg shadow-lg max-w-lg mt-8 modal-medio2">
            <h3 className="text-xl font-bold mb-6 text-center text-resaltado text-right-align text-move-right">
              ¿Sobre qué temática te gustaría hablar?
            </h3>

            <form className="space-y-8" onSubmit={handleSubmit}>
              <div>
                <label className="block text-md-small text-center mb-2">
                  Mensaje
                </label>
                <textarea
                  className="custom-input w-full px-2 py-2 border rounded resize-none"
                  maxLength="300"
                  rows="4"
                  name="idea"
                  placeholder="(0 a 300 caracteres)"
                  value={formData.idea}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="w-full mb-6">
                <label className="block text-md-small mb-2">Nombre</label>
                <input
                  type="text"
                  className="custom-input w-full px-4 py-2"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full mb-6">
                <label className="block text-md-small mb-2">Apellido</label>
                <input
                  type="text"
                  className="custom-input w-full px-4 py-2"
                  name="surname"
                  value={formData.surname}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full mb-6">
                <label className="block text-md-small mb-2">Email</label>
                <input
                  type="email"
                  className="custom-input w-full px-4 py-2"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full mb-6">
                <label className="block text-md-small mb-2">Empresa</label>
                <input
                  type="text"
                  className="custom-input w-full px-4 py-2"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full mb-6">
                <label className="block text-md-small mb-2">Tipo empresa</label>
                <input
                  type="text"
                  className="custom-input w-full px-4 py-2"
                  name="companytype"
                  value={formData.companytype}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full mb-6">
                <label className="block text-md-small mb-2">País</label>
                <select
                  className="custom-input w-full px-4 py-2"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                >
                  <option value="">Selecciona un país</option>
                  <option>Argentina</option>
                  <option>Brasil</option>
                  <option>Chile</option>
                  <option>México</option>
                  <option>Estados Unidos</option>
                  <option>Canadá</option>
                  <option>España</option>
                  <option>Francia</option>
                  <option>Reino Unido</option>
                  <option>Alemania</option>
                  <option>Italia</option>
                  <option>Australia</option>
                  <option>Japón</option>
                  <option>China</option>
                  <option>India</option>
                  <option>Sudáfrica</option>
                  <option>Rusia</option>
                  <option>Colombia</option>
                  <option>Perú</option>
                  <option>Venezuela</option>
                  <option>Uruguay</option>
                  <option>Paraguay</option>
                  <option>Bolivia</option>
                  <option>Ecuador</option>
                  <option>Guatemala</option>
                  <option>Honduras</option>
                  <option>Costa Rica</option>
                  <option>Panamá</option>
                  <option>Nicaragua</option>
                  <option>El Salvador</option>
                  <option>Cuba</option>
                  <option>Puerto Rico</option>
                  <option>República Dominicana</option>
                  <option>Portugal</option>
                  <option>Suiza</option>
                  <option>Países Bajos</option>
                  <option>Dinamarca</option>
                  <option>Suecia</option>
                  <option>Noruega</option>
                  <option>Finlandia</option>
                  <option>Bélgica</option>
                  <option>Polonia</option>
                  <option>Grecia</option>
                  <option>Hungría</option>
                  <option>Croacia</option>
                  <option>Serbia</option>
                  <option>Eslovenia</option>
                  <option>Rumania</option>
                  <option>Ucrania</option>
                  <option>Turquía</option>
                  <option>Israel</option>
                  <option>Egipto</option>
                  <option>Marruecos</option>
                  <option>Túnez</option>
                  <option>Argelia</option>
                  <option>Arabia Saudita</option>
                  <option>Emiratos Árabes Unidos</option>
                  <option>Irán</option>
                  <option>Pakistán</option>
                  <option>Bangladés</option>
                  <option>Filipinas</option>
                  <option>Corea del Sur</option>
                  <option>Vietnam</option>
                  <option>Tailandia</option>
                  <option>Malasia</option>
                  <option>Indonesia</option>
                  <option>Singapur</option>
                  <option>Hong Kong</option>
                  <option>Nueva Zelanda</option>
                  <option>Kenia</option>
                  <option>Nigeria</option>
                  <option>Ghana</option>
                  <option>Etiopía</option>
                  <option>Senegal</option>
                  <option>Camerún</option>
                  <option>Zambia</option>
                  <option>Tanzania</option>
                </select>
              </div>

              {/* reCAPTCHA justo antes del botón de enviar */}
              <ReCAPTCHA
                sitekey="6Lf9904qAAAAAC72TVqojv2ALGSNvSnvS0-3L1MM" // Reemplaza con tu clave de reCAPTCHA
                onChange={handleRecaptcha} // Manejar el token de reCAPTCHA
              />
              <div className="captcha__description description mt-2 text-gray-500 text-sm">
                This question is for testing whether or not you are a human
                visitor and to prevent automated spam submissions.
              </div>

              <button
                type="submit"
                className="w-full1 bg-red-600 text-black py-2 rounded hover:bg-red-600 transition"
              >
                Enviar
                <span className="lampara-icono">💡</span>
              </button>
            </form>
          </div>
        </>
      )}
    </div>
  );
}

export default Contact;
